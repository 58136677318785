
import {partner} from "@/api/partner";

export default {
  data() {
    return {
      partnerList: [],
      bannerOptions: {
        loop: true, // 循环模式选项
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {// 自动滑动
          delay: 6000, //6秒切换一次
          disableOnInteraction: false
        },
        slidesPerView: 1,// 可显示的数量
        slidesPerGroup: 1,//移动个数
      },
      swiperList: [
        {
          title1: '多种网络类型测试能力',
          title2: '家宽、移动网络测试',
          in: '为网络工程师、装维人员、技术爱好者量身定制综合性网络测试工具，固网',
          in2: '家宽、移动网络均可测试，Android、Windows、iOS、macOS系统均可使用， 支持按企业定制化需求改造'
        },
        {
          title1: '让你用较少的投入获得专业、',
          title2: '精准的网络测试能力',
          in: '基于测速网开发的精准稳定测速内核和覆盖全球的服务器网络系统，',
          in2: '推出多种产品、功能SDK和解决方案，满足多个行业的客户需求，解决客户痛点'
        },
        {
          title1: '亿万用户的实测数据',
          title2: '全面、真实反映用户的网络状况',
          in: '基于测速网平台每天百万次的用户实测结果，提供全面、完整、',
          in2: '及时的网络速度、网络质量、网络覆盖、终端设备以及用户体验相关的数据和服务'
        },
        {title1: '实用且有针对性的解决方案', title2: '', in: '基于测速网丰富的基础服务，提供适合于各行业、预集成的产品', in2: '与能力组合，以满足企事业单位网络测试的相关需求'},
        {title1: '个性化量身定制', title2: '为企业创造更多的商业价值', in: '基于测速网自研高性能测速内核和分布式网络测试系统，', in2: '根据客户需求，量身定制各种网络测试产品'},
        {
          title1: '一站式业务咨询服务',
          title2: '深入了解市场行情、用户需求',
          in: '基于测速网多年B&C端用户服务及测速系统开发经验，为企业',
          in2: '洞察用户需求、产品运营策略及转型咨询、技术瓶颈等提供咨询服务'
        },
      ],
      solutionList: [
        {
          title: "网络质量监测系统", url: '/quality-monitoring',
          info: "互联网、物联网设备网络质量监测，后台远程下发拨测任务"
        },
        {
          title: "用户满意度提升", url: '/satisfaction-improvement',
          info: "精准洞察网内用户上网体验，提前解决问题，提升用户满意度"
        },
        {
          title: "测速系统私有化部署", url: '/speedtest-deployment',
          info: "基于测速网自研网络速度和网络体验测试系统、客户端工具等产品，为您提供私有化部署定制服务"
        },
        {
          title: "网络设备测速", url: '/network-equipment',
          info: "单/多网卡设备测速服务，可形成分段测速系统"
        },
        {
          title: "大带宽检测", url: '/high-bandwidth',
          info: "千兆、万兆以上带宽检测服务"
        },
        {
          title: "委托测试", url: '/testing-services',
          info: "基于测速网近20年的服务经验，为设备厂商提供专业的委托测试服务"
        },
        {
          title: "添加测速点", url: '/nodes',
          info: "为当地/网内用户提升测速体验"
        },
        {
          title: "内网测速", url: '/intranet',
          info: "精准分析内网中各网络环节、各区域的网速及网络质量情况，定位网络问题，提升用网体验"
        },
      ],
      dataServerList: [
        {info1: '网络速度和', info2: '质量数据'},
        {info1: '网络覆盖', info2: '数据'},
        {info1: '设备的网速和', info2: '信号功率数据'},
        {info1: '用户位置', info2: '数据'},
        {info1: '网络异常', info2: '用户数据'},
        {info1: '更多数据', info2: ''},
      ],
      zixunServerList: [
        {info1: '用户洞察'},
        {info1: '产品运营与转型'},
        {info1: '技术瓶颈突破'},
        {info1: '落地护航'},
      ],
      customList: [
        {info: '网络测速平台'},
        {info: '网络拨测软件'},
        {info: '网络装维软件'},
      ],
      clientList: [
        {info: 'Android'},
        {info: 'iOS'},
        {info: 'Windows'},
        {info: 'macOS'}],
      dataList: [
        {info: '测速节点覆盖'},
        {info: '每年用户'},
        {info: '网络装维软件'},
      ],
      sdkList: [
        {
          title: '网络速度与质量',
          active: true,
          children: [
            {
              title: '网络测速SDK',
              text: '测速网自研测速算法，多系统可用，App、PC应用、路由器等产品均可集成',
              link: '/speedtest-sdk'
            },
            {
              title: '网络测速插件',
              text: '多用于浏览器测速场景，WEB、移动端H5、小程序内嵌H5均可集成',
              link: '/plugin'
            },
            {
              title: '命令行测速CLI',
              text: '网络测速命令行，多用于PC设备',
              link: '/cli'
            },
            {
              title: '实时网速监测SDK',
              text: '设备实时上下行网络速度监测，多用于PC设备',
              link: '/realtime-sdk'
            },
          ]
        },
        {
          title: '用户感知测试',
          active: false,
          children: [
            {
              title: '网页打开速度SDK',
              text: '主流网站打开速度测试，适用于App、路由器等产品集成使用',
              link: '/web-open-speed-sdk'//-------------------------------------
            },
            {
              title: '游戏测速SDK',
              text: '主流游戏平台时延测试，适用于App、路由器等产品集成使用',
              link: '/game-sdk'
            },
            {
              title: '综合测速SDK',
              text: '视频播放质量测试工具，适用于安卓、iOS系统软件产品集成',
              link: '/combo-sdk'
            },
            {
              title: '视频测试SDK',
              text: '资讯、电商、视频等场景主流平台时延测试，适用于App、路由器等产品集成使用',
              link: '/video-sdk'
            },
          ]
        },
        {
          title: '场景化网络测试',
          active: false,
          children: [
            {
              title: '全屋Wi-Fi检测SDK',
              text: '全屋Wi-Fi信号强度覆盖、上网体验诊断测试，适用于安卓系统软件产品集成',
              link: '/whole-home-wifi-sdk'
            },
            {
              title: '异常诊断SDK',
              text: '定位网络问题来源，适用于安卓、iOS系统软件产品集成',
              link: '/appmalf-sdk'
            },
          ]
        },
        {
          title: '专项测试',
          active: false,
          children: [
            {
              title: 'Wi-Fi分析SDK',
              text: 'Wi-Fi设备参数、干扰、在线终端等状态分析，适用于安卓系统软件产品集成',
              link: '/wifi-analysis-sdk'//--------------------------
            },
            {
              title: '移动网络检测SDK',
              text: '移动4G、5G等网络，信号强度、邻区等状态分析，适用于安卓系统软件产品集成',
              link: '/mobile-network-sdk'//---------------------
            }
          ]
        },
      ],
      showSdkIndex: 0
    }
  },
  mounted() {
    sessionStorage.getItem('loginAlertShow');
    if (sessionStorage.getItem('loginAlertShow')) {
      this.$store.commit('LOGIN_ALERT', true);
      sessionStorage.removeItem('loginAlertShow');
    }
    this.getPartner();
    this.setSdkActive()
  },
  methods: {
    setSdkActive() {
      let index = sessionStorage.getItem('indexSdk');
      if (index) {
        this.clickSdkTitle(index)
      }
    },
    goPage(url) {
      location.href = url;
    },
    getPartner() {
      partner().then(res => {
        if (res.code === 0) {
          this.partnerList = res.data;
        }
      })
    },
    clickSdkTitle(index) {
      sessionStorage.setItem('indexSdk', index)
      this.sdkList.forEach(item => {
        item.active = false
      })
      this.sdkList[index].active = true
      this.showSdkIndex = index
    },
    goToSdkLink(link) {
      location.pathname = link;
    }
  },
}
