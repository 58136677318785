import request from '@/utils/request';
import { PARTNER_URL,PRO_APP_GET_PRICE } from './api-list';

// 合作伙伴
export function partner() {
  return request({
    url: PARTNER_URL,
    method: 'get',
  });
}

// 进行专业版会员价格
export function getProAppPrice(params) {
  return request({
    url: PRO_APP_GET_PRICE,
    method: 'get',
    params: params,
    baseUrlType: 1
  });
}